@use '../../../styles/_global-variables.scss' as global;

.row {
    width: 100%;
    position: relative;
}
.rowflex {
    display: flex;
    padding: 0 56px;
    gap: 75px;
}
.rowfixed {
    width: auto;
    margin: 0 16px;
    gap: 16px;
}
.sotaMenu {
    top: -74px;
    &.webview {
        top: 0;
    }
}
.sotaMenuVideo {
    &.webview {
        margin-top: 0;
    }
}
.webviewMarginTop {
    &.webview {
        margin-top: 90px;
    }
}
.noPadding {
    padding: 0;
}

.desktopEspaiSotaMenu {
    margin-top: 0px;
}

@media #{global.$VP_BEYOND_MOBILE} {
    // > 768
    .rowfixed {
        max-width: 956px;
        margin: 0 34px;
    }
}

@media #{global.$VP_BEYOND_TP} {
    // > 1024
    .rowfixed {
        max-width: 100%;
        padding: 0 56px;
        margin: 0;
    }

    .desktopEspaiSotaMenu {
        margin-top: calc(-72px - var(--gapRow));
    }
    .directes {
        padding: 0 32px;
    }
}

@media #{global.$VP_EXTRA_LARGE} {
    // > 1440
    .rowfixed {
        max-width: 1902px;
        margin: 0;
    }
    .directes {
        padding: 0 56px;
    }
}

@media #{global.$VP_SUPER_EXTRA_LARGE} {
    // > 1920
    .rowfixed {
        max-width: 2144px;
        margin: 0 auto;
        padding: 0 56px;
    }
}

@media #{global.$VP_INGENT} {
    // >2256px
    .rowfixed {
        padding: 0;
    }
}

.col12_12 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
@media (min-width: 1920px) {
    .directes {
        gap: 150px;
        max-width: 1920px;
        margin: 0 auto;
    }
}
@media #{global.$VP_TP} {
    .directes {
        padding: 0 34px;
        display: flex;
        gap: 16px;
    }
    .sotaMenu {
        top: -56px;
        &.webview {
            top: 0;
        }
    }
}

@media #{global.$VP_ALL_MOBILE} {
    .rowflex {
        display: block;
        padding: 0 16px;
    }
    .directes {
        display: flex;
        gap: 16px;
    }
    .sotaMenu {
        top: -56px;
        &.webview {
            top: 0;
        }
    }
    .noPadding {
        padding: 0;
    }
}
